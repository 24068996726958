interface approvalStateType {
  classText: string,
  text: string,
}

export const approvalState = (state: number): approvalStateType => {
  switch (state) {
    case 1:
      return {
        classText: 'relStata-nothing',
        text: '机审中',
      };
    case 2:
      return {
        classText: 'relStata-stop',
        text: '机审未通过',
      };
    case 3:
      return {
        classText: 'relStata-stop',
        text: '未审核',
      };
    case 4:
      return {
        classText: 'relStata-pass',
        text: '审核通过',
      };
    case 5:
      return {
        classText: 'relStata-stop',
        text: '审核未通过',
      };
    case 6:
      return {
        classText: 'relStata-stop',
        text: '已删除',
      };
    default:
      return {
        classText: 'relStata-nothing',
        text: '状态有误',
      };
  }
};
