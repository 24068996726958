import { isObject } from 'lodash-es';

/**
 * url 参数实例
 * 通过URLParams.get('a')获取对应值
 */
export const URLParams = new URLSearchParams(window.location.search);

/**
 * 生成 URL 链接
 * @param params 链接参数对象
 * @param baseUrl 链接
 */
export function createURL(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: Record<string, any> = {},
  baseUrl = '',
): string {
  const searchParams = new URLSearchParams('');
  Object.entries(params).forEach(([key, value]) => {
    if (value) {
      searchParams.append(
        key,
        isObject(value) ? JSON.stringify(value) : value,
      );
    }
  });
  const search = searchParams.toString();
  if (!search) {
    return baseUrl;
  }
  if (!baseUrl) {
    return search;
  }
  return baseUrl + (baseUrl.indexOf('?') < 0 ? '?' : '&') + search;
}

/**
 * 移除链接中的协议头
 * @param src 链接
 */
export function removeHttp(src: string): string {
  return src.replace(/^http(s?):/, '');
}

/**
 * 获取hash参数
 * @param variable
 */

export function getQueryVariable(variable: string): string  {
  // 返回的access_token是放在hash里面的
  const query = window.location.hash.substring(1);
  // const query = window.location.hash.substring(1);
  const vars = query.split('&');
  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let i = 0;i < vars.length;i++) {
    const pair = vars[i].split('=');
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return '';
}


/**
 * 判断是否在iframe内部
 */

export function isInnerIframe(): boolean {
  return self !== top;
}
