const APPID = '101934126';
const AppIDTest = '101934375';
const AppIDPre = '101941296';

const AuthorizeUrl = 'https://graph.qq.com/oauth2.0/authorize';

const CallBackUrl = 'http://whiteboard.sparta.html5.qq.com:8080/';

const CallBackUrlTest = 'http://yybcommunity.sparta.html5.qq.com/';
const CallBackUrlPre = 'http://yybcommunity-pre.sparta.html5.qq.com/';

export interface IappValue {
  APPID: string
  CallBackUrl: string
}
interface mapAppInterface {
  [key: string]: IappValue
}

const mapApp: mapAppInterface = {
  dev: {
    APPID,
    CallBackUrl,
  },
  test: {
    APPID: AppIDTest,
    CallBackUrl: CallBackUrlTest,
  },
  pre: {
    APPID: AppIDPre,
    CallBackUrl: CallBackUrlPre,
  },
};

export {
  APPID,
  AppIDTest,
  AuthorizeUrl,
  CallBackUrl,
  CallBackUrlTest,
  CallBackUrlPre,
  mapApp,
};
